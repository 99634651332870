.content {
  width: 100%;
  max-width: 520px;
  padding: 10px;
  margin: auto;
}

.header {
  padding: 1.25rem;
  text-align: center;
}
