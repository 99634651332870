.root {
  background: #ecf0f1;
  padding: 10px;
  margin-top: 60px;

  h2 {
    margin-top: 0;
  }

  code {
    font-family: monospace;
  }
}
