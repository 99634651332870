@font-face {
  font-family: 'Inter';
  src: url('./Inter-Regular.woff2') format('woff2'),
    url('./Inter-Regular.woff') format('woff'),
    url('./Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Regular.woff2') format('woff2'),
    url('./Inter-Regular.woff') format('woff'),
    url('./Inter-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-SemiBold.woff2') format('woff2'),
    url('./Inter-SemiBold.woff') format('woff'),
    url('./Inter-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-SemiBold.woff2') format('woff2'),
    url('./Inter-SemiBold.woff') format('woff'),
    url('./Inter-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
